<template>
  <div>
    <br />
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="card">
            <div class="card-header">Nueva Contraseña</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="email" class="form-label text-md-right"
                      >Dirección de email</label
                    >
                    <input
                      v-model="logObj.email"
                      type="email"
                      class="form-control"
                      value=""
                      required
                      autocomplete="email"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="password" class="form-label text-md-right"
                      >Contraseña</label
                    >
                    <input
                      v-model="logObj.password"
                      type="password"
                      class="form-control"
                      required
                      autocomplete="new-password"
                    />

                    <span class="invalid-feedback" role="alert">
                      <strong>Error</strong>
                    </span>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label
                      for="password-confirm"
                      class="form-label text-md-right"
                      >Repita contraseña</label
                    >
                    <input
                      v-model="logObj.c_password"
                      type="password"
                      class="form-control"
                      required
                      autocomplete="new-password"
                    />
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <div class="col-md-12">
                    <button
                      type="submit"
                      :disabled="butonDisabledReset"
                      class="btn btn-block btn-outline-mhmotors"
                    >
                      {{ buttonTextReset }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecuperarContrasena",
  data() {
    return {
      butonDisabledReset: false,
      buttonTextReset: "Cambiar Contraseña",
      logObj: {
        email: "",
        password: "",
        c_password: ""
      }
    };
  },
  computed: {},
  created() {
    //token;
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.butonDisabledReset = true;
      this.buttonTextReset = "Cargando ...";

      const formData = {
        token: this.$route.params.token,
        email: this.logObj.email,
        password: this.logObj.password,
        password_confirmation: this.logObj.c_password
      };

      this.$axios
        .post("/api/v1/reset", formData)
        .then(res => {
          console.log(res);
          this.butonDisabledReset = false;
          this.buttonTextReset = "Cambiar Contraseña";

          this.$router.push("/login");

          this.$swal.fire(
            "Contraseña restablecida exitosamente",
            "",
            "success"
          );
        })
        .catch(error => {
          this.butonDisabledReset = false;
          this.buttonTextReset = "Cambiar Contraseña";
          this.$bvToast.toast(error.response.data.message, {
            title: "Cambiar Contraseña",
            variant: "info",
            solid: true
          });
        });
    }
  }
};
</script>
